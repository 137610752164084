import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import {
  configAction, divisaAction, darkModeAction, updateUser, updateProviderRoles, updateProviderLM, updateProviderTotalBooks,
} from '@shared/redux/actions/actions-store-state.model';
import { environment } from '@environments/environment';
import { DARK_MODE } from '@shared/data-structure/dark-mode';
import { CURRENCY_WORTH } from '@shared/data-structure/currency-worth';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private authFirebaseService: AuthFirebaseService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }

  load() {
    if (this.isBrowser) {
      const dark = localStorage.getItem(DARK_MODE);
      if (dark)
        this.store.dispatch(darkModeAction({ darkMode: dark == 'true' ? true : false }));

      // Load dataproviders
      this.http.get(`${environment.URL_API_AV}/api/dataprovider/av`)
        .subscribe((res: any) => {
          this.store.dispatch(updateProviderLM({ lm: res.result.legalmatter }));
          this.store.dispatch(updateProviderRoles({ roles: res.result.authorroles }));
          this.store.dispatch(updateProviderTotalBooks({ totalBooks: res.result.totalBooks }));

          const dollar = res.result.currency.dollar ? res.result.currency.dollar : CURRENCY_WORTH;
          const euro = res.result.currency.euro ? res.result.currency.euro : CURRENCY_WORTH;
          this.store.dispatch(divisaAction({ dollar: dollar, euro: euro }));
        });

      // Listen and load info del usuario, luego de loguearse
      this.authFirebaseService.getDataSessionUser()
        .subscribe(user => {
          if (user) {
            setTimeout(() => {

              this.http.get(`${environment.URL_API_AV}/api/user/me`)
                .subscribe((res: any) => {
                  this.store.dispatch(updateUser({ user: res.result.data }));
                  this.store.dispatch(configAction({
                    darkMode: res.result.data.darkmode,
                    language: res.result.data.language,
                    currency: res.result.data.currency
                  }));
                }, err => {
                  /*
                    Si el usuario no existe, queda la sesión abierta.
                    Verificar acá y en ProfileComponent.getUserIfNotExist
                  */
                });

            }, 2500);
          }
        });
    }
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  /*   dispatchAlertUser(): void {
      this.store.dispatch(emitAlert({
        message: this._t.instant('alert.login_user_not_found'),
        alertType: 'danger'
      }));
    } */

}

export function servicesOnRun(config: AppConfigService): () => void {
  return () => {
    config.load();
  };
}
