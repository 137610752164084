import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
/* import { CustomPreloadingStrategyService } from '@shared/services/custom-preloading-strategy.service'; */
import { QuicklinkStrategy } from 'ngx-quicklink';

import { AuthenticationGuard, AuthenticationChildGuard } from '@shared/guards/authentication.guard';
import { AutorizationBooksGuard } from '@shared/guards/autorization-books.guard';
import { AutorizationCPanelGuard } from '@shared/guards/autorization-cpanel.guard';

import { SubsSuccessMpComponent } from '@shared/components/@purchases/subs-success-mp/subs-success-mp.component';
import { SubsSuccessPpComponent } from '@shared/components/@purchases/subs-success-pp/subs-success-pp.component';

export const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule),
    data: { preload: true },
  },
  {
    path: 'author',
    loadChildren: () => import('./modules/author/author.module').then(m => m.AuthorModule),
    data: { preload: true },
  },
  {
    path: 'catalog',
    loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule),
    data: { preload: true },
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
    data: { preload: true },
  },
  {
    path: 'cpanel',
    canActivate: [AuthenticationGuard, AutorizationCPanelGuard],
    loadChildren: () => import('./modules/cpanel/cpanel.module').then(m => m.CPanelModule),
    data: { preload: true },
  },
  {
    path: 'history',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
    data: { preload: true },
  },
  {
    path: 'library',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/library/library.module').then(m => m.LibraryModule),
    data: { preload: true },
  },
  {
    path: 'login',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    data: { preload: true },
  },
  {
    path: 'policy',
    loadChildren: () => import('./modules/policy-privacy/policy-privacy.module').then(m => m.PolicyPrivacyModule),
    data: { preload: false },
  },
  {
    path: 'pricing',
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule),
    data: { preload: true },
  },
  {
    path: 'profile',
    canActivateChild: [AuthenticationChildGuard],
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    data: { preload: true },
  },
  {
    path: 'reader',
    canActivate: [AuthenticationGuard, AutorizationBooksGuard],
    loadChildren: () => import('./modules/reader/reader.module').then(m => m.ReaderModule),
    data: { preload: true },
  },
  {
    path: 'register',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule),
    data: { preload: true },
  },
  {
    path: 'searcher',
    loadChildren: () => import('./modules/searcher/searcher.module').then(m => m.SearcherModule),
    data: { preload: true },
  },
  {
    path: 'security',
    loadChildren: () => import('./modules/security/security.module').then(m => m.SecurityModule),
    data: { preload: true },
  },
  {
    path: 'subs',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/subs/subs.module').then(m => m.SubsModule),
    data: { preload: true },
  },
  {
    path: 'terms',
    loadChildren: () => import('./modules/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
    data: { preload: false },
  },
  {
    path: '404',
    loadChildren: () => import('./modules/page-404/page-404.module').then(m => m.Page404Module),
    data: { preload: false },
  },
  {
    path: 'subs-success/mp',
    component: SubsSuccessMpComponent,
  },
  {
    path: 'subs-success/pp',
    component: SubsSuccessPpComponent,
  },
  {
    path: '',
    loadChildren: () => import('./modules/searcher/searcher.module').then(m => m.SearcherModule),
    data: { preload: true },
  },
  {
    path: '**',
    redirectTo: '404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0]
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
